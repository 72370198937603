import RequestDefinition from "../classes/RequestDefinition";

// Username/Password login
export const login = new RequestDefinition({
  path: "api/login",
  method: "post",
  transformResponse: (response) => {
    return response.data?.token ?? null;
  },
});

// Get Authorization Code from a phone number
export const getAuthToken = new RequestDefinition({
  path: "api/token",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

// Log in with 6 digit auth code
export const loginCode = new RequestDefinition({
  path: "api/login-token",
  method: "post",
  transformResponse: (response) => {
    return response.data.token ?? null;
  },
});

// Okta tokens
export const oktaTokens = new RequestDefinition({
  path: "api/okta-token",
  method: "post",
  transformResponse: (response) => {
    return response.data ?? null;
  },
});

// Okta refresh tokens
export const oktaRefreshToken = new RequestDefinition({
  path: "api/okta-refresh-token",
  method: "post",
  transformResponse: (response) => {
    return response.data ?? null;
  },
});

// CFA location members
export const cfaLocationMembers = new RequestDefinition({
  path: "api/company/cfa-location-members",
  method: "post",
  transformResponse: (response) => {
    return response.data ?? null;
  },
});

// Get list of company invites
export const getTeamInvites = new RequestDefinition({
  path: "api/company-invites/{phone}",
  method: "get",
  transformResponse: (response) => {
    return response.data.content.invites ?? null;
  },
});

export const getCompanyDetails = new RequestDefinition({
  path: "api/company/details",
  method: "get",
  transformResponse: (response) => {
    return response.data.content ?? null;
  },
});

// Accept a team invite
export const acceptInvitation = new RequestDefinition({
  path: "api/accept-invite",
  method: "post",
  transformResponse: (response) => {
    return response.data.content ?? null;
  },
});

// Ignore a team invite
export const ignoreInvitation = new RequestDefinition({
  path: "api/ignore-invite",
  method: "post",
  transformResponse: (response) => {
    return response.data.content ?? null;
  },
});

export const signup = new RequestDefinition({
  path: "api/user-signup",
  method: "post",
  transformResponse: (response) => {
    return response.data?.token ?? null;
  },
});

export const updateCompanyLocation = new RequestDefinition({
  path: "api/company/update-locations",
  method: "post",
  transformResponse: (response) => {
    return response.data ?? null;
  },
});

export const createTeam = new RequestDefinition({
  path: "api/signup",
  method: "post",
  transformResponse: (response) => {
    if (!response.data || !response.data.content.companies) {
      return null;
    }

    const { companies } = response.data.content;
    const newCompany = companies[companies.length - 1];

    // Return the list of companies and new company
    return { companies, newCompany };
  },
});

export const verify = new RequestDefinition({
  path: "api/verify",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const triggerPasswordReset = new RequestDefinition({
  path: "api/trigger-password-reset",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const resetPassword = new RequestDefinition({
  path: "api/reset-password",
  method: "post",
  transformResponse: (response) => {
    return response.data?.token ?? null;
  },
});

export const logout = new RequestDefinition({
  path: "api/logout",
  method: "post",
});

// Returns user table, properties
export const getUserDetails = new RequestDefinition({
  path: "api/user/details",
  method: "get",
  transformResponse: (response) => {
    if (!response.data?.content?.user) {
      return null;
    }

    return {
      userDetails: response.data.content.user,
      minAppVersion: response.data?.min_app_version || null,
    };
  },
});

// Updates user table
export const updateUserDetails = new RequestDefinition({
  path: "api/user/details",
  method: "post",
  transformResponse: (response) => {
    return response.data.content.user ?? null;
  },
});

export const updateLocale = new RequestDefinition({
  path: "api/user/locale",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.user ?? null;
  },
});

export const getCompanies = new RequestDefinition({
  path: "api/companies",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.companies ?? null;
  },
});

export const setDefaultCompany = new RequestDefinition({
  path: "api/default-company",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

// BILLING / SUBSCRIPTIONS

export const getBillingPortalUrl = new RequestDefinition({
  path: "api/company/billing/url",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const getBillingSummary = new RequestDefinition({
  path: "api/company/billing/summary",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content.subscription ?? null;
  },
});

export const getPrice = new RequestDefinition({
  path: "api/company/billing/price",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.price ?? null;
  },
});

export const acknowledgeSubscription = new RequestDefinition({
  path: "api/company/billing/acknowledge",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const updateBilling = new RequestDefinition({
  path: "api/company/billing/subscription",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content.data ?? null;
  },
});

export const getManagers = new RequestDefinition({
  path: "api/company/managers",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.managers ?? null;
  },
});

export const createEmployeeCheckin = new RequestDefinition({
  path: "api/company/employee/checkin",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const deferEmployeeCheckin = new RequestDefinition({
  path: "api/company/employee/checkin",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const getEmployee = new RequestDefinition({
  path: "api/company/employee",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.employee ?? null;
  },
});

export const getEmployeeMemoSummary = new RequestDefinition({
  path: "api/company/employee/memo-summary",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.employee ?? null;
  },
});

export const getCSVData = new RequestDefinition({
  path: "api/company/employee/memos-csv",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const getReadMemos = new RequestDefinition({
  path: "api/company/employee/read-memos",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const getUnreadMemos = new RequestDefinition({
  path: "api/company/employee/unread-memos",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const createEmployee = new RequestDefinition({
  path: "api/company/employee/new",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.employee ?? null;
  },
});

export const deleteEmployee = new RequestDefinition({
  path: "api/company/employee",
  method: "delete",
  transformResponse: (response) => {
    return response.data || null;
  },
});

export const inviteEmployee = new RequestDefinition({
  path: "api/company/employees/invite",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const getEmployeesCelebrations = new RequestDefinition({
  path: "api/company/employees/celebrations",
  method: "post",
  transformResponse: (response) => {
    return response.data.content.celebrations ?? null;
  },
});

export const requestEmployee = new RequestDefinition({
  path: "api/company/employee/request",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const unrequestEmployee = new RequestDefinition({
  path: "api/company/employee/unrequest",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const bulkUpdateEmployees = new RequestDefinition({
  path: "api/company/employees/bulk",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const editEmployee = new RequestDefinition({
  path: "api/company/employee",
  method: "post",
  transformRequest: (data) => {
    return {
      ...data,
      flagged_messages: null,
      flagged_messages_active: null,
      raised_hands: null,
      raised_hands_active: null,
      checkins: null,
      checkins_active: null,
      audiences: null,
      locations: null,
      managers: null,
      employees: null,
      manager_request_active: null,
      manager_requests: null,
      manager_requests_today: null,
    };
  },
  transformResponse: (response) => {
    return response.data?.content?.employee ?? null;
  },
});

export const getEmployees = new RequestDefinition({
  path: "api/company/employees",
  method: "get",
  transformResponse: (response) => {
    return {
      employees: response.data?.content?.employees ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
    };
  },
});

export const getEmployeesList = new RequestDefinition({
  path: "api/company/employees-list",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.employees ?? null;
  },
});

export const getEmployeesCompletedMessages = new RequestDefinition({
  path: "api/company/employees-completed",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

// Locations
export const getCompanyLocation = new RequestDefinition({
  path: "api/company/location",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.location ?? null;
  },
});

export const createCompanyLocation = new RequestDefinition({
  path: "api/company/location",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.location ?? null;
  },
});

export const deleteCompanyLocation = new RequestDefinition({
  path: "api/company/location",
  method: "delete",
  transformResponse: (response) => {
    return response.data || null;
  },
});

export const editCompanyLocation = new RequestDefinition({
  path: "api/company/location",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.location ?? null;
  },
});

export const getCompanyLocations = new RequestDefinition({
  path: "api/company/locations",
  method: "get",
  transformResponse: (response) => {
    return {
      employees: response.data?.content?.locations ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
    };
  },
});

export const getCompanyLocationsList = new RequestDefinition({
  path: "api/company/locations-list",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.locations ?? null;
  },
});

export const getCompanyLocationsByEmployee = new RequestDefinition({
  path: "api/company/employee-locations",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.locations ?? null;
  },
});

//Devices
export const getCompanyDevices = new RequestDefinition({
  path: "api/company/devices",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.devices ?? null;
  },
});

export const editCompanyDevices = new RequestDefinition({
  path: "api/company/device",
  method: "put",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const removeCompanyDevices = new RequestDefinition({
  path: "api/company/device",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

// Audiences
export const getCompanyAudience = new RequestDefinition({
  path: "api/company/audience",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.audience ?? null;
  },
});

export const createCompanyAudience = new RequestDefinition({
  path: "api/company/audience",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.audience ?? null;
  },
});

export const deleteCompanyAudience = new RequestDefinition({
  path: "api/company/audience",
  method: "delete",
  transformResponse: (response) => {
    return response.data || null;
  },
});

export const editCompanyAudience = new RequestDefinition({
  path: "api/company/audience",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.audience ?? null;
  },
});

export const getCompanyAudiencesList = new RequestDefinition({
  path: "api/company/audiences-list",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.audiences ?? null;
  },
});

// Messages

export const getMessages = new RequestDefinition({
  path: "api/company/messages", // ?company_id=1&audiences=1,2&order_by=id,asc&page=1&terms=office upgrade"
  method: "get",
  transformResponse: (response) => {
    return {
      messages: response.data?.content?.messages ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
    };
  },
});

export const getMessage = new RequestDefinition({
  path: "api/company/message", // ?company_id=1&id=1"
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.message ?? null;
  },
});

export const editMessage = new RequestDefinition({
  path: "api/company/message", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.message ?? null;
  },
});

export const deleteMessage = new RequestDefinition({
  path: "api/company/message", // ?company_id=1&id=1"
  method: "delete",
});

export const expireMessage = new RequestDefinition({
  path: "api/company/message/expire", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.message ?? null;
  },
});

export const archiveMessage = new RequestDefinition({
  path: "api/company/message/archive", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.message ?? null;
  },
});

export const createMessage = new RequestDefinition({
  path: "api/company/message/new", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.message ?? null;
  },
});

export const getFlaggedMessages = new RequestDefinition({
  path: "api/company/messages/flagged", // ?company_id=1&id=1"
  method: "get",
  transformResponse: (response) => {
    return {
      flagged_messages: response.data?.content?.flagged_messages ?? [],
      raised_hands: response.data?.content?.raised_hands ?? [],
      checkins: response.data?.content?.checkins ?? [],
      employees: response.data?.content?.employees ?? [],
    };
  },
});

export const getFlaggedMessage = new RequestDefinition({
  path: "api/company/message/flagged", // ?company_id=1&id=1"
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.flagged_message ?? [];
  },
});

export const acknowledgeFlaggedMessage = new RequestDefinition({
  path: "api/company/message/flagged", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.flagged_message ?? [];
  },
});

export const getCheckin = new RequestDefinition({
  path: "api/company/message/checkin", // ?company_id=1&id=1"
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.checkin ?? [];
  },
});

export const managerAcknowledgeCheckin = new RequestDefinition({
  path: "api/company/message/checkin", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.checkin ?? [];
  },
});

export const getRaisedHand = new RequestDefinition({
  path: "api/company/message/raised-hand", // ?company_id=1&id=1"
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.raised_hand ?? [];
  },
});

export const acknowledgeRaisedHand = new RequestDefinition({
  path: "api/company/message/raised-hand", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.raised_hand ?? [];
  },
});

export const attachMediaToMessage = new RequestDefinition({
  path: "api/company/message/media", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.message ?? [];
  },
});

export const detachMediaFromMessage = new RequestDefinition({
  path: "api/company/message/media/detach", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.message ?? [];
  },
});

export const uploadFile = new RequestDefinition({
  path: "api/company/upload", // ?company_id=1&id=1"
  method: "post",
  transformRequest: (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("name", data.name);
    formData.append("company_id", data.company_id);
    formData.append("message_id", data.message_id);
    formData.append("survey_id", data.survey_id);
    formData.append("celebration_id", data.celebration_id);
    return formData;
  },
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const getMedia = new RequestDefinition({
  path: "api/company/media", // ?company_id=1&id=1"
  method: "get",
  transformResponse: (response) => {
    return {
      media: response.data?.content?.media ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
    };
  },
});

export const getMediaFile = new RequestDefinition({
  path: "api/media/get",
  method: "get",
  transformResponse: (response) => {
    return { data: response.data.content.media ?? null };
  },
});

export const uploadSignature= new RequestDefinition({
  path: "api/media/upload/signature",
  method: "post",
  transformRequest: (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("memo_id", data.memo_id);
    formData.append("company_id", data.company_id);
    return formData;
  },
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const getMediaList = new RequestDefinition({
  path: "api/media/list",
  method: "post",
  transformResponse: (response) => {
    return {
      data: {
        media: response.data?.content?.media ?? [],
        current_page: response.data?.content?.current_page,
        total_pages: response.data?.content?.total_pages,
        sort_by: response.data?.content?.sort_by,
        sort_direction: response.data?.content?.sort_direction,
        filters: response.data?.content?.filters ?? {},
      },
    };
  },
});

export const uploadMedia = new RequestDefinition({
  path: "api/media/create",
  method: "post",
  transformRequest: (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("name", data.name);
    formData.append("company_id", data.company_id);
    formData.append("recipient_ids", data.recipient_ids);
    formData.append("location_ids", data.location_ids);
    formData.append("audience_ids", data.audience_ids);
    return formData;
  },
  transformResponse: (response) => {
    console.log("upload response: ", response);
    return {
      data: response.data?.content?.media ?? [],
    };
  },
});

export const updateMedia = new RequestDefinition({
  path: "api/media/update",
  method: "put",
  transformResponse: (response) => {
    return {
      data: response.data?.content?.media ?? null,
    };
  },
});

export const deleteMedia = new RequestDefinition({
  path: "api/media/delete",
  method: "delete",
  transformResponse: (response) => {
    return {
      data: response.data.successes ?? null,
    };
  },
});

// export const downloadMedia =
//   new RequestDefinition({
//     path: file.filepath,
//     method: "get",
//     transformResponse: (response) => {
//       // const contentDisposition = response.headers?.["content-disposition"];
//       const filename = file.name ?? file.filename;
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", filename); //or any other extension
//       document.body.appendChild(link);
//       link.click();
//       return response.data?.content ?? null;
//     },
//   });

export const getSurveys = new RequestDefinition({
  path: "api/company/surveys", // ?company_id=1&audiences=1,2&order_by=id,asc&page=1&terms=office upgrade"
  method: "get",
  transformResponse: (response) => {
    return {
      surveys: response.data?.content?.surveys ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
    };
  },
});

export const getSurvey = new RequestDefinition({
  path: "api/company/survey", // ?company_id=1&id=1"
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.survey ?? null;
  },
});

export const editSurvey = new RequestDefinition({
  path: "api/company/survey", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.survey ?? null;
  },
});

export const deleteSurvey = new RequestDefinition({
  path: "api/company/survey", // ?company_id=1&id=1"
  method: "delete",
});

export const expireSurvey = new RequestDefinition({
  path: "api/company/survey/expire", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.survey ?? null;
  },
});

export const createSurvey = new RequestDefinition({
  path: "api/company/survey/new", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.survey ?? null;
  },
});

export const getSurveySummary = new RequestDefinition({
  path: "api/company/survey/summary", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    const contentDisposition = response.headers?.["content-disposition"];
    const filename =
      contentDisposition.split(";")?.[1]?.split("=")?.[1] ?? "pushkin-survey-summary.csv";
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    return response.data?.content ?? null;
  },
});

export const getEmployeeSummary = new RequestDefinition({
  path: "api/company/employees/download", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    const contentDisposition = response.headers?.["content-disposition"];
    const filename =
      contentDisposition.split(";")?.[1]?.split("=")?.[1] ?? "pushkin-employee-summary.csv";
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    return response.data?.content ?? null;
  },
});

export const getEmployeeManagerSummary = new RequestDefinition({
  path: "api/company/employee-managers/download", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    const contentDisposition = response.headers?.["content-disposition"];
    const filename =
      contentDisposition.split(";")?.[1]?.split("=")?.[1] ??
      "pushkin-employee-managers-summary.csv";
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    return response.data?.content ?? null;
  },
});

export const getSurveyResponses = new RequestDefinition({
  path: "api/company/survey/responses", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    const contentDisposition = response.headers?.["content-disposition"];
    const filename =
      contentDisposition.split(";")?.[1]?.split("=")?.[1] ?? "pushkin-survey-responses.csv";
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    return response.data?.content ?? null;
  },
});

export const attachMediaToSurvey = new RequestDefinition({
  path: "api/company/survey/media", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.survey ?? [];
  },
});

export const detachMediaFromSurvey = new RequestDefinition({
  path: "api/company/survey/media/detach", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.survey ?? [];
  },
});

export const getCelebrations = new RequestDefinition({
  path: "api/company/celebrations", // ?company_id=1&audiences=1,2&order_by=id,asc&page=1&terms=office upgrade"
  method: "get",
  transformResponse: (response) => {
    return {
      celebrations: response.data?.content?.celebrations ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
    };
  },
});

export const getCelebration = new RequestDefinition({
  path: "api/company/celebration", // ?company_id=1&id=1"
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.celebration ?? null;
  },
});

export const editCelebration = new RequestDefinition({
  path: "api/company/celebration", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.celebration ?? null;
  },
});

export const deleteCelebration = new RequestDefinition({
  path: "api/company/celebration", // ?company_id=1&id=1"
  method: "delete",
});

export const expireCelebration = new RequestDefinition({
  path: "api/company/celebration/expire", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.celebration ?? null;
  },
});

export const createCelebration = new RequestDefinition({
  path: "api/company/celebration/new", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.celebration ?? null;
  },
});

export const attachMediaToCelebration = new RequestDefinition({
  path: "api/company/celebration/media", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.celebration ?? [];
  },
});

export const detachMediaFromCelebration = new RequestDefinition({
  path: "api/company/celebration/media/detach", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.celebration ?? [];
  },
});

export const translateStrings = new RequestDefinition({
  path: "api/company/translate", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? [];
  },
});

export const getCompanyCheckins = new RequestDefinition({
  path: "api/company/checkins", // ?company_id=1&id=1"
  method: "get",
  transformResponse: (response) => {
    return response.data?.content ?? [];
  },
});

export const scheduleCompanyCheckins = new RequestDefinition({
  path: "api/company/checkins", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? [];
  },
});

export const uploadEmployeesPreview = new RequestDefinition({
  path: "api/company/employees/upload", // ?company_id=1&id=1"
  method: "post",
  transformRequest: (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("missing_employees", data.missing_employees);
    formData.append("company_id", data.company_id);
    return formData;
  },
  headers: {
    "Content-Type": "multipart/form-data",
  },
  transformResponse: (response) => {
    return response.data?.content ?? [];
  },
});

export const uploadEmployeesRun = new RequestDefinition({
  path: "api/company/employees/upload/confirm", // ?company_id=1&id=1"
  method: "post",
  transformRequest: (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("missing_employees", data.missing_employees);
    formData.append("company_id", data.company_id);
    return formData;
  },
  headers: {
    "Content-Type": "multipart/form-data",
  },
  transformResponse: (response) => {
    return response.data?.content ?? [];
  },
});

export const uploadManagerEmployeesPreview = new RequestDefinition({
  path: "api/company/employees/manager-upload", // ?company_id=1&id=1"
  method: "post",
  transformRequest: (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("missing_employees", data.missing_employees);
    formData.append("company_id", data.company_id);
    return formData;
  },
  headers: {
    "Content-Type": "multipart/form-data",
  },
  transformResponse: (response) => {
    return response.data?.content ?? [];
  },
});

export const uploadManagerEmployeesRun = new RequestDefinition({
  path: "api/company/employees/upload/manager-confirm", // ?company_id=1&id=1"
  method: "post",
  transformRequest: (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("missing_employees", data.missing_employees);
    formData.append("company_id", data.company_id);
    return formData;
  },
  headers: {
    "Content-Type": "multipart/form-data",
  },
  transformResponse: (response) => {
    return response.data?.content ?? [];
  },
});

export const getReportWeeklyLogin = new RequestDefinition({
  path: "api/company/reports/weekly-login",
  method: "post",
  transformResponse: (response) => {
    const contentDisposition = response.headers?.["content-disposition"];
    const filename =
      contentDisposition.split(";")?.[1]?.split("=")?.[1] ?? "pushkin-report-weekly-logins.csv";
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    return response.data?.content ?? null;
  },
});

export const getReportWeeklyText = new RequestDefinition({
  path: "api/company/reports/weekly-login-text",
  method: "post",
  transformResponse: (response) => {
    const contentDisposition = response.headers?.["content-disposition"];
    const filename =
      contentDisposition.split(";")?.[1]?.split("=")?.[1] ?? "pushkin-report-weekly-logins.csv";
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    return response.data?.content ?? null;
  },
});

export const getChatGroups = new RequestDefinition({
  path: "api/chat/groups/get", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.groups ?? null;
  },
});

export const createChatGroup = new RequestDefinition({
  path: "api/chat/group", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.group ?? null;
  },
});

export const createPrivateChatGroup = new RequestDefinition({
  path: "api/chat/privategroup", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.group ?? null;
  },
});

export const updateChatGroup = new RequestDefinition({
  path: "api/chat/group/{group_id}", // ?company_id=1&id=1"
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.group ?? null;
  },
});

export const deleteChatGroup = new RequestDefinition({
  path: "api/chat/group/{group_id}", // ?company_id=1&id=1"
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const getChatGroupMessages = new RequestDefinition({
  path: "api/chat/group/{group_id}/get", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.messages ?? null;
  },
});

export const createChatGroupMessage = new RequestDefinition({
  path: "api/chat/group/{group_id}", // ?company_id=1&id=1"
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.message ?? null;
  },
  transformRequest: (data) => {
    const formData = new FormData();
    // formData.append("image", data.image);
    if (data.content) {
      formData.append("content", data.content);
    }
    if (data.chat_message_id) {
      formData.append("chat_message_id", data.chat_message_id);
    }
    if (data.image) {
      formData.append("image", data.image);
    }
    if (data.image_url) {
      formData.append("image_url", data.image_url);
    }
    return formData;
  },
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const updateChatGroupMessage = new RequestDefinition({
  path: "api/chat/group/{group_id}/{message_id}", // group/{group_id}/{message_id}
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.message ?? [];
  },
  // transformRequest: (data) => {
  //   const formData = new FormData();
  //   // formData.append("image", data.image);
  //   formData.append("content", data.content);
  //   return formData;
  // },
  // headers: {
  //   "Content-Type": "multipart/form-data",
  // },
});

export const markChatGroupMessagesRead = new RequestDefinition({
  path: "api/chat/group/{group_id}/read",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const toggleChatGroupMessageReaction = new RequestDefinition({
  path: "api/chat/group/{group_id}/{message_id}/react",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.message ?? null;
  },
});

export const deleteChatGroupMessage = new RequestDefinition({
  path: "api/chat/group/{group_id}/{message_id}",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.message ?? null;
  },
});

export const taskList = new RequestDefinition({
  path: "api/tasks/list",
  method: "post",
  transformResponse: (response) => {
    return {
      tasks: response.data?.content?.tasks ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
      filters: response.data?.content?.filters ?? {},
    };
  },
});

export const taskGet = new RequestDefinition({
  path: "api/tasks/get",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.task ?? null;
  },
});

export const taskCreate = new RequestDefinition({
  path: "api/tasks/create",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.task ?? null;
  },
});

export const taskUpdate = new RequestDefinition({
  path: "api/tasks/update",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.task ?? null;
  },
});

export const taskDelete = new RequestDefinition({
  path: "api/tasks/delete",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const taskReopen = new RequestDefinition({
  path: "api/tasks/reopen",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.task ?? null;
  },
});

export const taskView = new RequestDefinition({
  path: "api/tasks/view",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const taskAssign = new RequestDefinition({
  path: "api/tasks/assign",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.task ?? null;
  },
});

export const taskStart = new RequestDefinition({
  path: "api/tasks/start",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.task ?? null;
  },
});

export const taskComplete = new RequestDefinition({
  path: "api/tasks/complete",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.task ?? null;
  },
});

export const taskApprove = new RequestDefinition({
  path: "api/tasks/approve",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.task ?? null;
  },
});

export const taskComment = new RequestDefinition({
  path: "api/tasks/comment",
  method: "post",
  transformRequest: (data) => {
    const formData = new FormData();
    formData.append("company_id", data.company_id);
    formData.append("content", data.content);
    if (data?.image) {
      formData.append("image", data.image);
    }
    if (data?.task_comment_id) {
      formData.append("task_comment_id", data.task_comment_id);
    }
    formData.append("task_id", data.task_id);
    return formData;
  },
  transformResponse: (response) => {
    return response.data?.content?.task ?? null;
  },
});

export const taskCommentEdit = new RequestDefinition({
  path: "api/tasks/comment/edit",
  method: "post",
  transformRequest: (data) => {
    const formData = new FormData();
    formData.append("company_id", data.company_id);
    formData.append("content", data.content);
    formData.append("id", data.id);
    formData.append("task_id", data.task_id);
    if (data?.image) {
      formData.append("image", data.image);
    }
    return formData;
  },
  transformResponse: (response) => {
    return response.data?.content?.task ?? null;
  },
});

export const taskCommentDelete = new RequestDefinition({
  path: "api/tasks/comment/delete",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.task ?? null;
  },
});

export const tagList = new RequestDefinition({
  path: "api/tags/list",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.tags ?? [];
  },
});

export const tagGet = new RequestDefinition({
  path: "api/tags/get",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.tag ?? null;
  },
});

export const tagCreate = new RequestDefinition({
  path: "api/tags/create",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.tag ?? null;
  },
});

export const tagUpdate = new RequestDefinition({
  path: "api/tags/update",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.tag ?? null;
  },
});

export const tagDelete = new RequestDefinition({
  path: "api/tags/delete",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const settingsAudiences = new RequestDefinition({
  path: "api/settings/audiences",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.audiences ?? null;
  },
});

export const settingsLocations = new RequestDefinition({
  path: "api/settings/locations",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.locations ?? null;
  },
});

export const settingsTags = new RequestDefinition({
  path: "api/settings/tags",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.tags ?? null;
  },
});

export const settingsEmployees = new RequestDefinition({
  path: "api/settings/employees",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.employees ?? null;
  },
});

export const settingsMe = new RequestDefinition({
  path: "api/settings/me",
  method: "get",
  transformResponse: (response) => {
    return {
      employee: response.data?.content?.employee ?? null,
      using_employee_as: response.data?.content?.using_employee_as ?? null,
      user: response.data?.content?.user ?? null,
      company: response.data?.content?.company ?? null,
      companies: response.data?.content?.companies ?? null,
    };
  },
});

export const settingsFlagsUpdate = new RequestDefinition({
  path: "api/settings/flags",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.flags ?? null;
  },
});

export const memoList = new RequestDefinition({
  path: "api/memos/list",
  method: "post",
  transformResponse: (response) => {
    return {
      memos: response.data?.content?.memos ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
      filters: response.data?.content?.filters ?? {},
    };
  },
});

export const memosAll = new RequestDefinition({
  path: "api/memos/all",
  method: "post",
  transformResponse: (response) => {
    return {
      memos: response.data?.content?.memos ?? [],
    };
  },
});

export const memoGet = new RequestDefinition({
  path: "api/memos/get",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.memo ?? null;
  },
});

export const memoCreate = new RequestDefinition({
  path: "api/memos/create",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.memo ?? null;
  },
});

export const memoUpdate = new RequestDefinition({
  path: "api/memos/update",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.memo ?? null;
  },
});

export const memoDelete = new RequestDefinition({
  path: "api/memos/delete",
  method: "delete",
  transformResponse: (response) => {
    return response.data || null;
  },
});

export const memoDeleteRecipients = new RequestDefinition({
  path: "api/memos/delete-recipients",
  method: "delete",
  transformResponse: (response) => {
    return response.data || null;
  },
});

export const memoRecipients = new RequestDefinition({
  path: "api/memos/recipients",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const memoTemplateList = new RequestDefinition({
  path: "api/memo-templates/list",
  method: "post",
  transformResponse: (response) => {
    return {
      memo_templates: response.data?.content?.memo_templates ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
      filters: response.data?.content?.filters ?? {},
    };
  },
});

export const memoTemplateGet = new RequestDefinition({
  path: "api/memo-templates/get",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.memo_template ?? null;
  },
});

export const memoTemplateMediaAttach = new RequestDefinition({
  path: "api/memo-templates/media",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.memo_template ?? null;
  },
});

export const memoTemplateMediaDetach = new RequestDefinition({
  path: "api/memo-templates/media",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.memo_template ?? null;
  },
});

export const memoTemplateGetMedia = new RequestDefinition({
  path: "api/memo-templates/media",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.media ?? null;
  },
});

export const memoTemplateCreate = new RequestDefinition({
  path: "api/memo-templates/create",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.memo_template ?? null;
  },
});

export const memoTemplateUpdate = new RequestDefinition({
  path: "api/memo-templates/update",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.memo_template ?? null;
  },
});

export const memoTemplateDelete = new RequestDefinition({
  path: "api/memo-templates/delete",
  method: "delete",
  transformResponse: (response) => {
    return response.data || null;
  },
});

export const memoTemplateArchive = new RequestDefinition({
  path: "api/memo-templates/archive",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.memo_template ?? null;
  },
});

export const memoTemplateUnarchive = new RequestDefinition({
  path: "api/memo-templates/unarchive",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.memo_template ?? null;
  },
});

export const memoTemplateUse = new RequestDefinition({
  path: "api/memo-templates/use",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_send ?? null;
  },
});

export const memoTemplateSendList = new RequestDefinition({
  path: "api/memo-template-sends/list",
  method: "post",
  transformResponse: (response) => {
    return {
      memo_template_sends: response.data?.content?.memo_template_sends ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
      filters: response.data?.content?.filters ?? {},
    };
  },
});

export const memoTemplateSendGet = new RequestDefinition({
  path: "api/memo-template-sends/get",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_send ?? null;
  },
});

export const memoTemplateSendCreate = new RequestDefinition({
  path: "api/memo-template-sends/create",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_send ?? null;
  },
});

export const memoTemplateSendUpdate = new RequestDefinition({
  path: "api/memo-template-sends/update",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_send ?? null;
  },
});

export const memoTemplateSendDelete = new RequestDefinition({
  path: "api/memo-template-sends/delete",
  method: "delete",
  transformResponse: (response) => {
    return response.data || null;
  },
});

export const memoTemplateSendListDelete = new RequestDefinition({
  path: "api/memo-template-sends/delete/list",
  method: "delete",
  transformResponse: (response) => {
    return response.data || null;
  },
});

export const memoTemplateSendArchive = new RequestDefinition({
  path: "api/memo-template-sends/archive",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_send ?? null;
  },
});

export const memoTemplateSendUnarchive = new RequestDefinition({
  path: "api/memo-template-sends/unarchive",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_send ?? null;
  },
});

export const memoTemplateSendDrafts = new RequestDefinition({
  path: "api/memo-template-sends/drafts",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_send ?? null;
  },
});

export const memoTemplateSendPublish = new RequestDefinition({
  path: "api/memo-template-sends/publish",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_send ?? null;
  },
});

export const memoTemplateSendMediaAttach = new RequestDefinition({
  path: "api/memo-template-sends/media",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_send ?? null;
  },
});

export const memoTemplateSendMediaDetach = new RequestDefinition({
  path: "api/memo-template-sends/media",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_send ?? null;
  },
});

export const memoTemplateSendGetMedia = new RequestDefinition({
  path: "api/memo-template-sends/media",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.media ?? null;
  },
});

export const memoTemplateSendRemoveMemo = new RequestDefinition({
  path: "api/memo-template-sends/memo",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_send ?? null;
  },
});

export const memoTemplateSendUpdateMemo = new RequestDefinition({
  path: "api/memo-template-sends/memo",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_send ?? null;
  },
});

export const memoTemplateSendAddMediaToMemo = new RequestDefinition({
  path: "api/memo-template-sends/memo/media",
  method: "post",
  transformRequest: (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("memo_id", data.memo_id);
    formData.append("company_id", data.company_id);
    return formData;
  },
  headers: {
    "Content-Type": "multipart/form-data",
  },
  transformResponse: (response) => {
    return response.data?.content?.memo_template_send ?? null;
  },
});

export const memoTemplateSendRemoveMediaFromMemo = new RequestDefinition({
  path: "api/memo-template-sends/memo/media",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_send ?? null;
  },
});

export const memoTemplatePackageList = new RequestDefinition({
  path: "api/memo-template-packages/list",
  method: "post",
  transformResponse: (response) => {
    return {
      memo_template_packages: response.data?.content?.memo_template_packages ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
      filters: response.data?.content?.filters ?? {},
    };
  },
});

export const memoTemplatePackageTemplateList = new RequestDefinition({
  path: "api/memo-template-packages/list-templates",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.memo_templates ?? null;
  },
});

export const memoTemplatePackageGet = new RequestDefinition({
  path: "api/memo-template-packages/get",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_package ?? null;
  },
});

export const memoTemplatePackageCreate = new RequestDefinition({
  path: "api/memo-template-packages/create",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_package ?? null;
  },
});

export const memoTemplatePackageUpdate = new RequestDefinition({
  path: "api/memo-template-packages/update",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_package ?? null;
  },
});

export const memoTemplatePackageDelete = new RequestDefinition({
  path: "api/memo-template-packages/delete",
  method: "delete",
  transformResponse: (response) => {
    return response.data || null;
  },
});

export const memoTemplatePackageAttachTemplate = new RequestDefinition({
  path: "api/memo-template-packages/template",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_package ?? null;
  },
});

export const memoTemplatePackageAttachTemplateUpdate = new RequestDefinition({
  path: "api/memo-template-packages/template/update",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_package ?? null;
  },
});

export const memoTemplatePackageDetachTemplate = new RequestDefinition({
  path: "api/memo-template-packages/template",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_package ?? null;
  },
});

export const memoTemplatePackageUse = new RequestDefinition({
  path: "api/memo-template-packages/use",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.memo_template_package ?? null;
  },
});

export const getSelfEmployee = new RequestDefinition({
  path: "api/app/company/get-employee/{company_id}",
  method: "get",
  transformResponse: (response) => {
    return response.data.content.user ?? null;
  },
});

export const getAllMessages = new RequestDefinition({
  path: "api/app/employee-messages",
  method: "post",
  transformResponse: (response) => {
    return response.data.content ?? null;
  },
});

export const acknowledgeMessage = new RequestDefinition({
  path: "api/app/employee-message/acknowledge",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const flagMessage = new RequestDefinition({
  path: "api/app/employee-message/flag",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const flagMessageRemove = new RequestDefinition({
  path: "api/app/employee-message/flag-remove",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const acknowledgeSurvey = new RequestDefinition({
  path: "api/app/employee-survey",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const raiseHand = new RequestDefinition({
  path: "api/app/employee-hand/raise",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const raiseHandAcknowledge = new RequestDefinition({
  path: "api/app/employee-hand/acknowledge",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const acknowledgeCelebration = new RequestDefinition({
  path: "api/app/employee-celebrate",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const acknowledgeCelebrationSelf = new RequestDefinition({
  path: "api/app/employee-celebrate/acknowledge",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const acknowledgeCheckin = new RequestDefinition({
  path: "api/app/employee-checkin/acknowledge",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const getHomePageData = new RequestDefinition({
  path: "api/home",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const memoResponseGetAll = new RequestDefinition({
  path: "api/memo-responses",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const memoResponseCelebration = new RequestDefinition({
  path: "api/memo-responses/celebration",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.memo ?? null;
  },
});

export const formTemplateList = new RequestDefinition({
  path: "api/forms/listTemplates",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content;
  },
});

export const formGet = new RequestDefinition({
  path: "api/forms/get",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.form ?? null;
  },
});

export const formCreate = new RequestDefinition({
  path: "api/forms/create",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.form ?? null;
  },
});

export const formResponseGet = new RequestDefinition({
  path: "api/forms/getResponse",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.form_response ?? null;
  },
});

export const formResponseCreate = new RequestDefinition({
  path: "api/forms/createResponse",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.form_response ?? null;
  },
});

export const formResponseUpdate = new RequestDefinition({
  path: "api/forms/updateResponse",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.form_response ?? null;
  },
});

export const formResponseDelete = new RequestDefinition({
  path: "api/forms/deleteResponse",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const formResponseUpload = new RequestDefinition({
  path: "api/forms/uploadFile",
  method: "post",
  transformRequest: (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("id", data.id);
    formData.append("company_id", data.company_id);
    return formData;
  },
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const formResponseEmail = new RequestDefinition({
  path: "api/forms/emailResponse",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const scheduledSendsList = new RequestDefinition({
  path: "api/scheduled-sends/list",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.scheduled_sends ?? null;
  },
});

export const scheduledSendsListSendables = new RequestDefinition({
  path: "api/scheduled-sends/list-sendables",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const scheduledSendGet = new RequestDefinition({
  path: "api/scheduled-sends/get",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.scheduled_send ?? null;
  },
});

export const scheduledSendCreate = new RequestDefinition({
  path: "api/scheduled-sends/create",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.scheduled_send ?? null;
  },
});

export const scheduledSendUpdate = new RequestDefinition({
  path: "api/scheduled-sends/update",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.scheduled_send ?? null;
  },
});

export const scheduledSendDelete = new RequestDefinition({
  path: "api/scheduled-sends/delete",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const syncByUserCompany = new RequestDefinition({
  path: "api/sync/user/company",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const editCompanyMember = new RequestDefinition({
  path: "api/sync/user/company/edit-member",
  method: "put",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});
