import { createRouter, createWebHistory } from "vue-router";
import constants from "@/utils/constants";
import Dashboard from "@/pages/Dashboard.vue";
import Company from "@/pages/Dashboard/Company.vue";
import Login from "@/pages/Login";
import Logout from "@/pages/Logout";
import { upperFirst, words } from "lodash-es";
import { useAuth } from "@/stores/auth";

const routes = [
  {
    path: "/",
    component: Dashboard,
    name: "Selection",
    meta: {
      authGuard: true,
    },
    props: (route) => ({
      show: route.query.show,
    }),
  },
  {
    path: "/login",
    name: constants.LOGIN_ROUTE,
    component: Login,
    props: (route) => ({
      redirect: route.query.redirect,
      query: route.query.query,
      reason: route.query.reason,
      token: route.query.token,
    }),
  },
  {
    path: "/request-password-reset",
    name: "RequestPasswordReset",
    component: () => import(/* webpackChunkName: "auth" */ "@/pages/RequestPasswordReset.vue"),
    props: (route) => ({
      redirect: route.query.redirect,
      query: route.query.query,
      reason: route.query.reason,
      token: route.query.token,
    }),
  },
  {
    path: "/reset-password",
    name: "PasswordReset",
    component: () => import(/* webpackChunkName: "auth" */ "@/pages/PasswordReset.vue"),
    props: (route) => ({
      email: route.query.email,
      token: route.query.token,
    }),
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import(/* webpackChunkName: "auth" */ "@/pages/Signup.vue"),
  },
  {
    path: "/create-team",
    name: "CreateTeam",
    component: () => import(/* webpackChunkName: "auth" */ "@/pages/CreateCompany.vue"),
    props: true,
    meta: {
      authGuard: true,
    },
  },
  {
    path: "/:company_id/cfa-locations",
    name: "cfaLocations",
    component: () => import("@/pages/SyncCFA.vue"),
    props: (route) => ({
      companyId: route.params.company_id,
    }),
    meta: {
      authGuard: true,
    },
  },
  {
    path: "/join-team",
    name: "JoinTeam",
    component: () => import(/* webpackChunkName: "auth" */ "@/pages/JoinTeam.vue"),
    props: true,
    meta: {
      authGuard: true,
    },
  },
  {
    name: "Profile",
    path: "/profile",
    component: () => import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Profile.vue"),
    meta: {
      // tab: "settings",
      isParent: false,
    },
  },
  {
    path: "/:company_id",
    component: Company,
    props: (route) => ({
      companyId: route.params.company_id,
    }),
    children: [
      // default
      {
        name: constants.HOME_ROUTE,
        path: "",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/Home.vue"),
        meta: {
          tab: "home",
        },
      },

      // SETTINGS
      {
        name: "Settings",
        path: "settings",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/SettingsIndex.vue"),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: {
          tab: "settings",
          isParent: true,
        },
      },
      {
        name: "SettingsFlags",
        path: "settings/flags",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/SettingsFlags.vue"),
        meta: {
          tab: "settings",
        },
      },
      // {
      //   name: "EmployeeListFromSetting",
      //   path: "settings/employees",
      //   component: () =>
      //     import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/EmployeesList.vue"),
      //   meta: {
      //     tab: "settings",
      //     isParent: true,
      //   },
      // },
      {
        name: "ReportDownload",
        path: "settings/reports",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/ReportDownload.vue"
          ),
        meta: {
          tab: "settings",
          isParent: false,
        },
      },
      {
        name: "Subscription",
        path: "settings/subscription",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/Subscription.vue"),
        meta: {
          tab: "settings",
          isParent: false,
        },
      },
      // {
      //   name: "SettingsChangeTeam",
      //   path: "settings/change-team",
      //   component: () =>
      //     import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/ChangeTeam.vue"),
      //   meta: {
      //     tab: "settings",
      //     isParent: false,
      //   },
      // },
      {
        name: "SettingsTeamInvitations",
        path: "settings/team-invitations",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/TeamInvitations.vue"),
        meta: {
          tab: "settings",
          isParent: false,
        },
      },
      {
        name: "CheckinsOverview",
        path: "settings/checkins",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/Checkins.vue"),
        meta: {
          tab: "settings",
          isParent: true,
        },
      },
      {
        name: "LocationsList",
        path: "settings/locations",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/LocationsList.vue"),
        meta: {
          tab: "settings",
          isParent: true,
        },
      },
      {
        name: "DevicesList",
        path: "settings/devices",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/DevicesList.vue"),
        meta: {
          tab: "settings",
          isParent: true,
        },
      },
      {
        name: "LocationNew",
        path: "settings/new-location",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/LocationNew.vue"),
        meta: {
          tab: "settings",
        },
      },
      {
        name: "LocationEdit",
        path: "settings/location/:id",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/LocationEdit.vue"),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: {
          tab: "settings",
        },
      },
      {
        name: "AudiencesList",
        path: "settings/audiences",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/AudiencesList.vue"),
        meta: {
          tab: "settings",
          isParent: true,
        },
      },
      {
        name: "AudienceNew",
        path: "settings/new-audience",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/AudienceNew.vue"),
        meta: {
          tab: "settings",
        },
      },
      {
        name: "AudienceEdit",
        path: "settings/audience/:id",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/AudienceEdit.vue"),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: {
          tab: "settings",
        },
      },

      // misc
      {
        name: "Media",
        path: "media",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/Media.vue"),
        meta: {
          tab: "media",
        },
      },

      // misc
      {
        name: "Files",
        path: "files",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/Files.vue"),
        meta: {
          tab: "files",
        },
      },

      //followups
      {
        name: "FlaggedMessageList",
        path: "flags",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/FlaggedMessages.vue"
          ),
        meta: {
          tab: "flags",
        },
      },

      // tasks
      {
        name: "TasksList",
        path: "tasks",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/TasksList.vue"),
        meta: {
          tab: "tasks",
          isParent: true,
        },
      },
      {
        name: "TaskDetail",
        path: "tasks/:id",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/TaskDetailPage.vue"
          ),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: {
          tab: "tasks",
        },
      },

      // memos
      {
        name: "MemosList",
        path: "memos",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/MemosList.vue"),
        meta: {
          tab: "memos",
          isParent: true,
        },
      },
      {
        name: "MemoDetail",
        path: "memo/:id",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/MemoDetail.vue"),
        props: (route) => {
          let from = route.query.from;

          try {
            const newFrom = JSON.parse(from);
            from = newFrom;
          } catch (e) {
            from = null;
          }

          return {
            id: route.params.id,
            from: from,
          };
        },
        meta: {
          tab: "memos",
        },
      },

      // memo templates
      {
        name: "MemoTemplatesList",
        path: "memo-templates",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/MemoTemplatesList.vue"
          ),
        meta: {
          tab: "memo-templates",
          isParent: true,
        },
      },
      {
        name: "MemoTemplateDetail",
        path: "memo-template/:id",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/MemoTemplateDetail.vue"
          ),
        props: (route) => {
          return {
            id: route.params.id,
          };
        },
        meta: {
          tab: "memo-templates",
        },
      },

      // memo template sends
      {
        name: "MemoSendsList",
        path: "memo-sends",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/MemoTemplateSendsList.vue"
          ),
        meta: {
          tab: "memo-sends",
          isParent: true,
        },
      },
      {
        name: "MemoSendDetail",
        path: "memo-send/:id",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/MemoTemplateSendDetail.vue"
          ),
        props: (route) => {
          return {
            id: route.params.id,
          };
        },
        meta: {
          tab: "memo-sends",
        },
      },

      {
        name: "MemoPackagesList",
        path: "memo-packages",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/MemoPackagesList.vue"
          ),
        meta: {
          tab: "memo-packages",
          isParent: true,
        },
      },
      {
        name: "MemoPackageDetail",
        path: "memo-package/:id",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/MemoPackageDetail.vue"
          ),
        props: (route) => {
          return {
            id: route.params.id,
          };
        },
        meta: {
          tab: "memo-packages",
        },
      },

      {
        name: "MemoScheduler",
        path: "memo-scheduler",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/MemoScheduler.vue"),
        meta: {
          tab: "memo-scheduler",
          isParent: true,
        },
      },

      // employees
      {
        name: "EmployeeList",
        path: "employees",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/EmployeesList.vue"),
        meta: {
          tab: "team",
          isParent: true,
        },
        props: {
          nonAdmin: true,
        },
      },
      {
        name: "EmployeeBulkEdit",
        path: "employees/bulk-edit",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/EmployeesBulkEdit.vue"
          ),
        meta: {
          tab: "team",
          isParent: false,
        },
      },
      {
        name: "EmployeeBulkImport",
        path: "employees/employee-import",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/EmployeeBulkImport.vue"
          ),
        meta: {
          tab: "team",
          isParent: false,
        },
      },
      {
        name: "EmployeeBulkManagerImport",
        path: "employees/employee-manager-import",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/EmployeeBulkManagerImport.vue"
          ),
        meta: {
          tab: "team",
          isParent: false,
        },
      },
      {
        name: "EmployeeNew",
        path: "employees/new",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/EmployeeNew.vue"),
        meta: {
          tab: "team",
        },
      },
      {
        name: "EmployeeDetail",
        path: "employee/:id",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/EmployeeDetail.vue"
          ),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: {
          tab: "team",
        },
      },
      {
        name: "EmployeeDetailFromList",
        path: "employee/:id",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/EmployeeDetail.vue"
          ),
        props: (route) => ({
          id: route.params.id,
          from: "list",
        }),
        meta: {
          tab: "team",
        },
      },
      {
        name: "EmployeeEdit",
        path: "employee/:id/edit",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/EmployeeEdit.vue"),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: {
          tab: "team",
        },
      },

      // chat
      {
        name: "ChatGroupList",
        path: "chats",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Company/ChatGroups.vue"),
        props: (route) => ({
          id: route.query.id,
        }),
        meta: {
          tab: "chat",
          isParent: true,
        },
      },
    ],
  },

  // CFA
  {
    name: constants.CFA_CONFIRM_ROUTE,
    path: "/callback",
    component: () =>
      import(/* webpackChunkName: "Cfa" */ "@/pages/Cfa.vue"),
    props: true,
    meta: {
      authGuard: true,
    },
  },
  {
    name: constants.CFA_INTEGRATION_ROUTE,
    path: "/cfa-integrate",
    component: () =>
      import(/* webpackChunkName: "Cfa" */ "@/pages/Cfa.vue"),
    props: true,
    meta: {
      authGuard: true,
    },
  },
  {
    name: "Payment",
    path: "/:company_id/payment/:status",
    component: () => import(/* webpackChunkName: "dashboard" */ "@/pages/Payment.vue"),
    props: (route) => ({
      status: route.params.status,
      companyId: route.params.company_id,
    }),
  },
  {
    name: constants.MAINTENANCE_ROUTE,
    path: constants.MAINTENANCE_PATH,
    component: () => import(/* webpackChunkName: "dashboard" */ "@/pages/MaintenanceMode.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// TODO: managerGuard and adminGuard
// look for onMount with isCompanyAdmin or isCompanyManger to replace
router.beforeEach((to, from) => {
  const authStore = useAuth();

  const failsAuthGuard = to.meta.authGuard && !authStore.isLoggedIn && authStore.isCheckedForAuth;
  const isLoginRoute = to.name === constants.LOGIN_ROUTE;
  const isCFARoute = to.name === constants.CFA_INTEGRATION_ROUTE || to.name === constants.CFA_CONFIRM_ROUTE;

  if (failsAuthGuard && !isLoginRoute ) {
    return { name: constants.LOGIN_ROUTE, query: { ...to.query, redirect: to.path } };
  }
  if (!authStore.isLoggedIn && isCFARoute) {
    return { name: constants.LOGIN_ROUTE, query: { ...to.query, redirect: to.path } };
  }
  return undefined;
});

const generateDocumentTitle = (to) => {
  if (to?.meta?.title) {
    return to.meta.title;
  }

  const nameWords = words(to.name);

  return `PushKin | ${nameWords.map((word) => upperFirst(word)).join(" ")}`;
};

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    document.title = generateDocumentTitle(to);
  }
});

export default router;
